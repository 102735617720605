<script>
import Vue2Datepicker from 'vue2-datepicker';

export default Vue2Datepicker;
</script>

<style lang="scss">
$default-color: $body-color;
$primary-color: $primary;
$disabled-color: $input-color;
$input-hover-border-color: $input-border-color;

@import 'vue2-datepicker/scss/index';
</style>

<style lang="scss" scoped>
.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.mx-zoom-in-down-enter,
.mx-zoom-in-down-leave-to {
  opacity: 0;
}

.mx-datepicker {
  width: 100%;
}

.mx-input,
.mx-datepicker-main {
  border-width: $input-border-width;
}

.mx-datepicker-main {
  font-family: inherit;
  font-size: inherit;
}

.mx-input {
  box-shadow: none;
  border-radius: $input-border-radius;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height;
  background-color: $input-bg;

  @include font-size($input-font-size);

  &:disabled {
    background-color: $input-disabled-bg;
    cursor: auto;
  }

  &:focus {
    box-shadow: $input-focus-box-shadow;
  }

  .is-invalid & {
    border-color: $form-feedback-invalid-color;
  }
}

.mx-icon-calendar,
.mx-icon-clear {
  right: $input-padding-x;
}

.mx-datepicker-footer {
  border-top-width: $border-width;
}

::v-deep {
  .mx-calendar {
    width: rem(280);
  }

  .mx-btn,
  .mx-calendar-header-label {
    font-size: inherit;
  }

  .mx-btn {
    line-height: rem(40);
  }

  .mx-calendar-header,
  .mx-time-header {
    height: rem(40);
  }

  .mx-calendar-content,
  .mx-time-content {
    height: rem(252);
  }

  .mx-table-date {
    td,
    th {
      height: rem(36);
      font-size: inherit;
    }
  }

  .mx-time-column {
    .mx-time-list::after {
      content: none;
    }

    .mx-time-item {
      font-size: inherit;
      height: rem(36);
    }
  }
}
</style>
