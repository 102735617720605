<template>
  <div>
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>

    <Vue2Datepicker
      v-bind="vue2Datepicker"
      :class="{ 'is-invalid': error }"
      :input-attr="attrs"
      :value="value"
      @input="$emit('input', $event)"
    >
      <template #icon-calendar>
        <ClockIcon v-if="vue2Datepicker.type === 'time'" />
        <CalendarIcon v-else />
      </template>
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="props">
        <slot :name="name" v-bind="props"></slot>
      </template>
    </Vue2Datepicker>

    <div class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import BaseField from './BaseField';
import Vue2Datepicker from '../vue2-datepicker/Vue2Datepicker';

import CalendarIcon from 'bootstrap-icons/icons/calendar.svg';
import ClockIcon from 'bootstrap-icons/icons/clock.svg';

export default {
  extends: BaseField,

  components: { Vue2Datepicker, CalendarIcon, ClockIcon },

  props: { vue2Datepicker: { default: () => ({}) } },
};
</script>
